import { formControlLabelClasses } from "@mui/material"
import React from "react"

import { getUserFromBrowser } from "./authUtils"
import WeekdayPicker from "./weekdayPicker"

export default class AddEditJoyFuel extends React.Component {
  constructor(props) {
    super(props)
    const user = getUserFromBrowser()
    console.log(`name: ${user.name} phone_number: ${user.phone_number}`)
    console.log(user)
    this.state = {
      name: user.name,
      phone_number: user.phone_number,
      duration: "15",
      timezone: "",
      joyfuel: "",
      day_of_week: [],
      hour: "",
      minute: "",
      time:
        typeof window !== "undefined" &&
        new Date().toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
          hour12: false,
        }),
    }
  }

  handleFieldChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  createSmsSchedule = () => {
    if (this.state.joyfuel === "") {
      alert("Please enter a JoyFuel description!")
      return
    }

    if (this.state.day_of_week.length === 0) {
      alert("Please select days for JoyFuel!")
      return
    }

    const body = JSON.stringify({
      name: this.state.name,
      // TODO: edit server so we don't have to pass this
      time: "2021-12-04 16:08:42.945781",
      phone_number: this.state.phone_number,
      duration: this.state.duration,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      joyfuel: this.state.joyfuel,
      // TODO: edit server so don't have to pass this
      month_of_year: "",
      // TODO: edit server so don't have to pass this
      day_of_month: "",
      day_of_week: this.state.day_of_week.join(","),
      hour: this.state.time.split(":")[0],
      minute: this.state.time.split(":")[1],
    })

    const url = process.env.MESSAGE_SERVER_URL

    console.log(`Creating SMS schedule on ${url}/create`)
    console.log(body)
    fetch(`${url}/create`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json, text/plain, */*",
      },
      body: body,
    }).then(response => {
      // Clear the state so new values can be submitted to the API
      this.setState({
        duration: "",
        timezone: "",
        joyfuel: "",
        day_of_week: [],
        hour: "",
        minute: "",
      })
      // Clear the form field values so the input boxes are empty again
      document
        .querySelectorAll("input.addMomentInputField")
        .forEach(element => (element.value = ""))
      this.props.onAddCallback()
      document.querySelector(`#createEditModal`).classList.toggle("is-active")

      return response
    })
    // .then(responseJson => console.log(responseJson))
  }

  handleWeekdayButtonClick = event => {
    const dayOfWeekClicked = event.target.value
    let dayOfWeekList = this.state.day_of_week
    if (!dayOfWeekList.includes(dayOfWeekClicked)) {
      dayOfWeekList.push(dayOfWeekClicked)
      this.setState({ day_of_week: dayOfWeekList })
    }
  }

  handleWeekdayResetClick = event => {
    this.setState({ day_of_week: [] })
  }

  handleTimeChange = event => {
    // Will fire undefined when select hour
    const value = event.target.value
    if (typeof value !== "undefined") {
      this.setState({ time: value })
    }
    console.log(`handleTimeChange: ${this.state.time}`)
  }

  handleDurationChange = event => {
    this.setState({ duration: event.target.value })
  }

  render() {
    var modifiers = {
      weekend: function (weekday) {
        return weekday == 0 || weekday == 6
      },
    }

    return (
      <div class="modal" id="createEditModal">
        <div class="modal-background" />
        <div class="modal-card">
          <header class="modal-card-head tpeMomentAddHeader">
            <p class="title">
              Add JoyFuel Moment <br></br>
              <p class="subtitle">
                What are you doing when you lose sense of time and feel pure
                delight?
              </p>
            </p>
          </header>
          <section class="modal-card-body tpeMomentsAdd">
            <div class="field">
              <label class="label tpeMomentsAddLables">Description</label>
              <input
                class="input is-dark"
                name="joyfuel"
                //class="input addMomentInputField"
                type="text"
                placeholder="describe your JoyFuel"
                onChange={this.handleFieldChange}
              />
            </div>

            <div class="field">
              <label class="label tpeMomentsAddLables">Day of week</label>
              <WeekdayPicker
                daysSelected={this.state.day_of_week}
                onWeekdayButtonClick={this.handleWeekdayButtonClick}
                onWeekdayResetClick={this.handleWeekdayResetClick}
              />
            </div>
            <div class="field">
              <label class="label tpeMomentsAddLables">Time</label>
              <input
                class="input is-dark"
                // Trying to get time to show up toward left on iphone
                // TODO: Make this actually work..
                style={{ "text-align": "left" }}
                type="time"
                value={this.state.time}
                onChange={this.handleTimeChange}
              />
            </div>
            <div class="field">
              <label class="label tpeMomentsAddLables">Duration</label>
              <div class="select is-dark">
                <select onChange={this.handleDurationChange}>
                  <option value={15}>15 min</option>
                  <option value={30}>30 min</option>
                  <option value={45}>45 min</option>
                  <option value={60}>60 min</option>
                </select>
              </div>
            </div>
            <div class="field is-grouped tpeCreateEditButtons">
              <div class="control">
                <button class="button is-dark" onClick={this.createSmsSchedule}>
                  Submit
                </button>
              </div>
              <div class="control">
                <button
                  class="button is-white"
                  href="/test"
                  onClick={() =>
                    document
                      .querySelector(`#createEditModal`)
                      .classList.toggle("is-active")
                  }
                >
                  Cancel
                </button>
              </div>
            </div>
          </section>
        </div>
      </div>
    )
  }
}
