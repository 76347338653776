import React from "react"

import { encodeUrlParams } from "./authUtils"
import { FaRegSmile, FaRegFrown, FaRegMeh } from "react-icons/fa"
import { ListItem } from "@mui/material"

export default class Feedback extends React.Component {
  constructor(props) {
    super(props)
  }

  submitFeedback(phoneNumber, scheduleNumber, feedbackDate, feedback) {
    console.log(
      `submitting feedback: ${phoneNumber} ${scheduleNumber} ${feedbackDate} ${feedback}`
    )

    typeof window !== "undefined" &&
      fetch(
        `/.netlify/functions/logFeedback?${encodeUrlParams({
          scheduleId: `${phoneNumber}-${scheduleNumber}`,
          feedbackDate: feedbackDate,
          feedback: feedback,
        })}`
      ).then(() => {
        document
          .querySelector(`#${this.props.modalId}`)
          .classList.toggle("is-active")
        this.props.onSubmitCallback()
      })
  }

  render() {
    const feedbackOptions = [
      {
        opacity: "100%",
        feedback: 4,
        label: "Joy",
      },
      {
        opacity: "75%",
        feedback: 3,
        label: "Good",
      },
      {
        opacity: "50%",
        feedback: 2,
        label: "OK",
      },
      {
        opacity: "25%",
        feedback: 1,
        label: "Eh",
      },
      {
        opacity: "2%",
        feedback: "none",
        label: "None",
      },
    ]

    return (
      <div class="modal" id={this.props.modalId}>
        <div class="modal-background" />
        {/* <header class="modal-card-head">
          <p class="title">{`Submit feedback for ${this.props.phoneNumber} ${this.props.scheduleNumber} ${this.props.feedbackDate}`}</p>
        </header> */}
        <div class="modal-card">
          <header class="modal-card-head tpeMomentAddHeader">
            <p class="title">Feedback on JoyFuel '{this.props.joyfuel}'</p>
          </header>
          <section class="modal-card-body has-text-centered">
            <div class="buttons is-grouped is-centered is-vcentered">
              {feedbackOptions.map((item, index) => {
                let buttonStyle = { opacity: item.opacity }
                if (item.label === "None") {
                  buttonStyle = {
                    opacity: "100%",
                    borderColor: "grey",
                    backgroundColor: "white",
                  }
                }
                return (
                  <div class="field">
                    <button
                      class="button is-rounded tpeFeedbackOptionButton"
                      style={buttonStyle}
                      onClick={() => {
                        this.submitFeedback(
                          this.props.phoneNumber,
                          this.props.scheduleNumber,
                          this.props.feedbackDate,
                          item.feedback
                        )
                      }}
                    ></button>
                    <label class="label" style={{ color: "black" }}>
                      {item.label}
                    </label>
                  </div>
                )
              })}
              {
                // Hack to align last button
              }
              <div class="field"></div>
            </div>
          </section>
        </div>
      </div>
    )
  }
}
