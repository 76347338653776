//TODO: fetch, make it sort of like mock-up, then auth, edit, delete
import React, { useState, useEffect } from "react"
import { Helmet } from "react-helmet"

import "../styles/styles.scss"
import AddEditJoyFuel from "../components/addEditJoyFuel"
import MomentsList from "../components/momentsList"
import Feedback from "../components/feedback"
import MomentStats from "../components/momentStats"
import { FaLock, FaPhone, FaPlus, FaUser } from "react-icons/fa"
import logo from "../images/PEQbw.png"
import vbcLogo from "../images/vbc_logo.png"

import {
  isLoggedInBrowser,
  getUserFromBrowser,
  setUserInBrowser,
  logoutFromBrowser,
} from "../components/authUtils"

export default function IndexPage() {
  /*
  To fix FOUC that occurs because the external
  fonts haven't loaded.

  Fixed according to:
  https://dev.to/fyfirman/how-to-fix-fout-flash-of-unstyled-text-in-react-1dl1


  useEffect hook gets called by React before drawing page. 
  Article explainiing `useEffect`:
  https://reactjs.org/docs/hooks-effect.html

  Here, we do some cool stuff :)
  */

  const [fontsLoaded, setFontsLoaded] = useState(false)

  // Get user info from the browser
  // If user info exists, user is logged in
  // Set the login state in component
  // to true if logged in to display correct
  // experience.
  const userInfo = getUserFromBrowser()
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(
    Object.keys(userInfo).length > 0
  )

  const [formName, setFormName] = useState("")
  const [formPhoneNumber, setFormPhoneNumber] = useState("")
  const [formPassword, setFormPassword] = useState("")
  const [momentsList, setMomentsList] = useState([])
  const [areMomentsLoaded, setAreMomentsLoaded] = useState(false)
  const [stats, setStats] = useState({})
  const [areStatsLoaded, setAreStatsLoaded] = useState(false)

  const createMomentsList = () => {
    const body = JSON.stringify({
      phone_number: getUserFromBrowser().phone_number,
      get_feedback_date: 1,
    })

    const url = process.env.MESSAGE_SERVER_URL

    console.log(`Creating moments list on ${url}/fetch`)
    // TODO: This may not work on Internet Explorer (polyfill)
    typeof window !== "undefined" &&
      fetch(`${url}/fetch`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json, text/plain, */*",
        },
        body: body,
      })
        .then(response => response.json())
        .then(responseJson => {
          setMomentsList(responseJson.moments)
          setAreMomentsLoaded(true)
        })
  }

  const fetchMomentsStats = () => {
    const url = `.netlify/functions/fetchCompletionRate?phoneNumber=${
      getUserFromBrowser().phone_number
    }`
    fetch(url)
      .then(response => response.json())
      .then(responseJson => {
        setStats(responseJson)
        setAreStatsLoaded(true)
      })
  }

  const refreshListAndStats = () => {
    createMomentsList()
    fetchMomentsStats()
  }

  useEffect(() => {
    // Load font and then set flag that fonts are loaded.
    // This component will render NULL into fonts are loaded.
    document.fonts.load("12px Oswald").then(() => setFontsLoaded(true))
  }, [])
  if (!areMomentsLoaded && isUserLoggedIn) {
    console.log("Runing createMomentsList")
    createMomentsList()
    fetchMomentsStats()
    console.log(momentsList)
  }

  // Render NULL if fonts aren't loaded
  if (!fontsLoaded) {
    return null
  }

  const login = () => {
    // Strip out all characters but #'s
    const phone_number_clean = ("" + formPhoneNumber).replace(/\D/g, "")
    if (phone_number_clean.length !== 10) {
      window.alert("Phone number must be 12 digits")
      return
    }

    // TODO: Create user table and fetch PW from db
    if (formPassword !== "password") {
      // raise error
      window.alert("Incorrect password")
      return
    }

    const user = {
      phone_number: phone_number_clean,
      name: formName,
    }
    // Setting in the browser (from authUtils)
    setUserInBrowser(user)
    createMomentsList()
    // Setting login status in state to trigger render
    setIsUserLoggedIn(true)
  }

  const logout = () => {
    logoutFromBrowser()
    setIsUserLoggedIn(false)
  }

  const handleLoginFieldChange = e => {
    // TODO: Form field validation as you type
    if (e.target.name === "name") {
      setFormName(e.target.value)
    } else if (e.target.name === "phone_number") {
      setFormPhoneNumber(e.target.value)
    } else if (e.target.name === "password") {
      setFormPassword(e.target.value)
    }
  }

  const toggleMenuActive = () => {
    // For mobile menu, call this when you want to change the state of the menu
    document.querySelector(".navbar-menu").classList.toggle("is-active")
    document.querySelector(".navbar-burger").classList.toggle("is-active")
  }

  if (isUserLoggedIn) {
    return (
      <div class="tpeMain">
        <Helmet title="JoyFuel Moments" />
        <nav
          class="navbar is-spaced"
          role="navigation"
          aria-label="main navigation"
        >
          <div class="navbar-brand">
            <a class="navbar-item" href="https://thepurposeequation.com">
              <img src={logo} width="112" height="28" />
            </a>

            <a
              role="button"
              class="navbar-burger"
              aria-label="menu"
              aria-expanded="false"
              data-target="tpeNavbar"
              onClick={toggleMenuActive}
            >
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </a>
          </div>

          <div id="tpeNavbar" class="navbar-menu">
            <div class="navbar-start"></div>

            <div class="navbar-end">
              <a class="navbar-item" onClick={logout}>
                Logout
              </a>
            </div>
          </div>
        </nav>
        {/* <div class="section"> */}
        <div class="tpeMomentsSection">
          <div class="columns is-centered">
            <div class="column is-half">
              <p class="title has-text-centered">My JoyFuel Moments</p>
              <div class="tpeMomentsStats">
                <MomentStats
                  areStatsLoaded={areStatsLoaded}
                  stats={stats}
                  hasMoments={momentsList.length > 0}
                />
              </div>
              <MomentsList
                momentsList={momentsList}
                areMomentsLoaded={areMomentsLoaded}
                onDeleteCallback={refreshListAndStats}
              />
            </div>
          </div>
          {momentsList.map((item, index) => {
            const [phone_number, schedule_number] = item.moment_id.split("-")
            const feedback_date = item.feedback_date
            /* Multiple feedback modals (one per moment that needs feedback)
          Identify the one to make active by phone number, schedule number 
          and feedback date.
           */
            if (feedback_date === "") {
              return
            }
            return (
              <Feedback
                modalId={`feedback_modal_${phone_number}_${schedule_number}_${feedback_date}`}
                phoneNumber={phone_number}
                scheduleNumber={schedule_number}
                feedbackDate={feedback_date}
                joyfuel={item.joyfuel}
                onSubmitCallback={refreshListAndStats}
              />
            )
          })}
        </div>{" "}
        <div class="centered">
          <button
            class="button is-black is-rounded tpeAddButton"
            onClick={() =>
              document
                .querySelector(`#createEditModal`)
                .classList.toggle("is-active")
            }
          >
            <span class="icon">
              <FaPlus />
            </span>
          </button>

          <AddEditJoyFuel onAddCallback={createMomentsList} />
        </div>
        <div class="footer has-text-centered">
          <p>
            Built with love by{" "}
            <a
              style={{ color: "white", textDecoration: "underline" }}
              href="https://valuebasedconsulting.io"
            >
              Value Based Consulting
            </a>{" "}
            <span class="icon-text">
              <span class="icon">
                <img src={vbcLogo} />
              </span>
            </span>
          </p>
        </div>
      </div>
    )
  } else {
    return (
      <div class="tpeMain">
        <Helmet title="JoyFuel Moments" />
        {/* <div class="section"> */}
        <nav
          class="navbar is-spaced"
          id="tpeNavbar"
          role="navigation"
          aria-label="main navigation"
        >
          <div class="navbar-brand">
            <a class="navbar-item" href="https://thepurposeequation.com">
              <img src={logo} width="112" height="28" />
            </a>
          </div>
        </nav>
        <div class="columns is-centered">
          <div class="column is-half">
            <p class="title has-text-centered tpeLoginHeader">
              Welcome to<br></br>JoyFuel Moments!
            </p>
            <br></br>
            {/* <p class="subtitle has-text-centered tpeLoginMessage">
              When in a state of joy, you loose sense of time and self and feel
              delight.
              <br />
              <br />
            </p> */}
            <div class="tpeLoginFields">
              <p class="subtitle has-text-centered tpeLoginInputLabel">
                Let's get started!
              </p>
              <div class="field">
                <label class="label tpeLoginInputLabel">Name</label>

                <div class="control has-icons-left">
                  <input
                    name="name"
                    class="input is-dark"
                    type="text"
                    onChange={handleLoginFieldChange}
                  />
                  <span class="icon is-small is-left">
                    <FaUser />
                  </span>
                </div>
              </div>
              <div class="field">
                <label class="label tpeLoginInputLabel">Phone Number</label>
                <div class="control has-icons-left">
                  <input
                    name="phone_number"
                    class="input is-dark"
                    type="text"
                    onChange={handleLoginFieldChange}
                  />
                  <span class="icon is-small is-left">
                    <FaPhone />
                  </span>
                </div>
              </div>
              <div class="field">
                <label class="label tpeLoginInputLabel">Password</label>
                <div class="control has-icons-left">
                  <input
                    name="password"
                    class="input is-dark"
                    type="password"
                    onChange={handleLoginFieldChange}
                  />
                  <span class="icon is-small is-left">
                    <FaLock />
                  </span>
                </div>
              </div>
              <div class="control">
                <button class="button" onClick={login}>
                  Login
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="footer has-text-centered">
          <p>
            Built with love by{" "}
            <a
              style={{ color: "white", textDecoration: "underline" }}
              href="https://valuebasedconsulting.io"
            >
              Value Based Consulting
            </a>{" "}
            <span class="icon-text">
              <span class="icon">
                <img src={vbcLogo} />
              </span>
            </span>
          </p>
        </div>
        {/* </div> */}
      </div>
    )
  }
}
