export const isBrowser = () => {
  return typeof window !== "undefined"
}

export const setUserInBrowser = user => {
  isBrowser() && window.localStorage.setItem("user", JSON.stringify(user))
}

export const getUserFromBrowser = () => {
  return isBrowser() && window.localStorage.getItem("user")
    ? JSON.parse(window.localStorage.getItem("user"))
    : {}
}

export const isLoggedInBrowser = () => {
  const user = getUserFromBrowser()
  // Return true if email exists, false otherwise
  return !!user.email
}

export const logoutFromBrowser = () => {
  setUserInBrowser({})
}

export const abbreviatedNumToDayConversion = {
  0: "Sun",
  1: "Mon",
  2: "Tue",
  3: "Wed",
  4: "Thu",
  5: "Fri",
  6: "Sat",
  7: "Sun",
}

export const formatNumberScheduleAbbreviated = numberScheduleStr => {
  if (numberScheduleStr.split(",").length === 7) {
    return "Every day"
  }
  const formattedScheduleArray = numberScheduleStr
    .split(",")
    .sort()
    .map(element => abbreviatedNumToDayConversion[element])
  return formattedScheduleArray.join(", ")
}

export function encodeUrlParams(data) {
  // Takes in object and returns encoded URL params
  // E.g. encode({email: test@gmail.com, name: Ryan}) returns
  // email=test%40gmail.com&name=Ryan
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}
