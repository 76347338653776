import React from "react"

import { FaTrashAlt, FaRegComment } from "react-icons/fa"

import { formatNumberScheduleAbbreviated } from "./authUtils"

export default class MomentsList extends React.Component {
  constructor(props) {
    super(props)
  }

  handleDelete = e => {
    /*
    1. Confirm popup
    2. If yes -> delete & re-render list
    3. If no -> do nothing
    */
    let id = e.target.id
    // If clicked on the path part of the icon, use the ID
    // attached to the SVG part of the icon
    if (e.target.nodeName === "path") {
      id = e.target.parentNode.parentElement.id
      console.log(`clicked path, using svg ID: ${id}`)
    }
    const splitId = id.split("-")
    const momentId = `${splitId[0]}-${splitId[1]}`
    const areYouSure =
      typeof window !== "undefined" &&
      window.confirm("Are you sure you want to delete?")
    if (areYouSure) {
      console.log(`Deleting: ${momentId}`)
      fetch(`${process.env.MESSAGE_SERVER_URL}/delete`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json, text/plain, */*",
        },
        body: JSON.stringify({
          moment_id: momentId,
        }),
      }).then(response => {
        this.props.onDeleteCallback()
        console.log("Deletion done")
      })
    } else {
      console.log("Cancelling deletion")
    }
  }

  handleUpdate = e => {
    const splitId = e.target.id.split("-")
    const momentId = `${splitId[0]}-${splitId[1]}`
    console.log(`Updating: ${momentId}`)
    console.log(this.state.moments_list)
    const momentInfo = this.state.moments_list.filter(
      element => element.moment_id === momentId
    )[0]
    console.log(momentInfo)
  }

  handleFeedback = e => {
    let id = e.target.id
    if (e.target.nodeName === "path") {
      id = e.target.parentNode.parentElement.id
      console.log(`clicked path, using svg ID: ${id}`)
    }
    let [_, _2, phone_number, schedule_number, feedback_date] = id.split("_")
    // Select which feedback modal to make active
    // by phone number, schedule number, feedback date
    const feedbackModal = document.querySelector(
      `#feedback_modal_${phone_number}_${schedule_number}_${feedback_date}`
    )
    feedbackModal.classList.toggle("is-active")
    console.log(feedbackModal.id)
    //feedbackModal.classList.toggle.toString("is-active")
    console.log(phone_number, schedule_number, feedback_date)
  }

  // TODO: Create component, this component captures one moment, create your event with metadata, with a button to delete and edit
  // Create a function that converts from 0 - 6 to days of week to show schedule
  // Call another function on submission to reverse that
  // Need to decide front end components (pop over, closing, etc) -- can look at VBC to help this
  // Making the text inputs selectors, etc, duration can be drop down, day of week
  // Delete will be pretty easy, delete by ID, with a popover confirmation (could be a component, window.confirm something)
  // New component, shows human readable moment id schedule with a delete button, on delete button click handler with confirmation, this.scheduleid
  // Component for each row, add delete component
  render() {
    if (this.props.areMomentsLoaded && this.props.momentsList.length > 0) {
      //   console.log(this.state.moments_list)
      const momentsListSorted = this.props.momentsList.sort(function (a, b) {
        if (a.moment_id < b.moment_id) {
          return -1
        }
        if (a.moment_id > b.moment_id) {
          return 1
        }
        return 0
      })
      return (
        <div class="tpeMomentsListContainer">
          {momentsListSorted.map((item, index) => {
            const hourInt = parseInt(item.hour)
            const [phone_number, schedule_number] = item.moment_id.split("-")
            const feedback_date = item.feedback_date
            return (
              <div class="columns is-mobile tpeMomentRow is-vcentered">
                <div class="column is-three-fifths">
                  <p class="is-size-5 has-text-weight-semibold">
                    {item.joyfuel}
                  </p>
                  <p class="is-size-6">
                    {formatNumberScheduleAbbreviated(item.day_of_week)} at{" "}
                    {(hourInt == 0 && "12") ||
                      (hourInt > 12 && hourInt - 12) ||
                      (hourInt <= 12 && hourInt)}
                    :
                    {(item.minute.length === 1 && "0" + item.minute) ||
                      (item.minute.length > 1 && item.minute)}
                    {(hourInt >= 12 && " PM") || (hourInt < 12 && " AM")}
                  </p>
                </div>
                <div class="column is-two-fifths is-vcentered">
                  <div class="has-text-right">
                    <button
                      id={`feedback_button_${phone_number}_${schedule_number}_${feedback_date}`}
                      class={`button tpeFeedbackButton ${
                        feedback_date !== "" ? "" : "is-hidden"
                      }`}
                      onClick={this.handleFeedback}
                    >
                      <FaRegComment
                        id={`feedback_icon_${phone_number}_${schedule_number}_${feedback_date}`}
                      />
                    </button>
                    <button
                      id={`${item.moment_id}-delete`}
                      class="button "
                      onClick={this.handleDelete}
                    >
                      <FaTrashAlt id={`${item.moment_id}-delete-icon`} />
                    </button>
                  </div>
                </div>
                <>
                  {/*
              TODO: Add back update. Abandoned because of state issues & shared create
              <button
                id={`${item.moment_id}-update`}
                class="button"
                onClick={this.handleUpdate}
              >
                Update
              </button>
              */}
                  {/*
                  TODO: Map over day of week to get multiple days of week
                  TODO: Fix  single zero i.e. shouldn't have 8:0
                 <br /> {convertFromCrontabToDayOfWeek(0)} {item.hour}:
                {item.minute} */}
                </>
              </div>
            )
          })}
        </div>
      )
    } else if (
      this.props.areMomentsLoaded &&
      this.props.momentsList.length === 0
    ) {
      return (
        <>
          <p class="tpeLoginMessage has-text-centered">
            You do not have any moments. Add some to get started! :)
          </p>
        </>
      )
    } else {
      return <></>
    }
  }
}
