import React from "react"

export default class WeekdayPicker extends React.Component {
  constructor(props) {
    super(props)
  }

  days = [
    {
      key: "sunday",
      label: "S",
    },
    {
      key: "monday",
      label: "M",
    },
    {
      key: "tuesday",
      label: "T",
    },
    {
      key: "wednesday",
      label: "W",
    },
    {
      key: "thursday",
      label: "T",
    },
    {
      key: "friday",
      label: "F",
    },
    {
      key: "saturday",
      label: "S",
    },
  ]

  render() {
    return (
      <>
        <div class="field is-grouped">
          {this.days.map((day, index) => {
            let isOutlinedClass = "is-outlined"
            if (this.props.daysSelected.includes(`${index}`)) {
              isOutlinedClass = ""
            }
            return (
              <p class="control">
                <button
                  class={`button is-small is-dark ${isOutlinedClass} is-rounded`}
                  id={`weekday-button-${index}`}
                  value={index}
                  type="submit"
                  onClick={this.props.onWeekdayButtonClick}
                >
                  {day.label}
                </button>
              </p>
            )
          })}
        </div>
        <div class="field">
          {this.props.daysSelected.length > 0 && (
            <a class="is-small" onClick={this.props.onWeekdayResetClick}>
              Reset
            </a>
          )}
        </div>
      </>
    )
  }
}
