import React from "react"

export default class MomentStats extends React.Component {
  render() {
    if (this.props.areStatsLoaded && this.props.hasMoments) {
      return (
        <div class="has-text-centered">
          <p class="is-size-6">Completion rate:</p>
          <p class="title is-4 has-text-weight-semibold">
            {(this.props.stats.completionRate * 100).toFixed(2) + "%"}
          </p>
          <p class="is-size-6">Total completed:</p>
          <p class="title is-4 has-text-weight-semibold">
            {this.props.stats.completed} of {this.props.stats.total}
          </p>
        </div>
      )
    } else {
      return <></>
    }
  }
}
